import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },

  routes: [
    //-------------------------------------------GLOBAL -------------------------------------
    {
      path: "/",
      name: "home",
      component: () => import("@/views/Beranda.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/dashboard",
      name: "dashboard",
      component: () => import("@/views/Dashboard.vue"),
      meta: {
        pageTitle: "Dashboard",
        breadcrumb: [
          {
            text: "Dashboard",
            active: true,
          },
        ],
      },
    },
    {
      path: "/blog",
      name: "front_list_blog",
      component: () => import("@/views/blog/ListBlog.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/blog/detail/:id",
      name: "front_detail_blog",
      component: () => import("@/views/blog/DetailBlog.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/fitur_activity/detail/:id",
      name: "front_detail_fituractivity",
      component: () => import("@/views/blog/DetailFiturActivity.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/product",
      name: "front_list_product",
      component: () => import("@/views/product/ListProduct.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/product/detail/:id",
      name: "front_detail_product",
      component: () => import("@/views/product/DetailProduct.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/paket",
      name: "front_list_paket",
      component: () => import("@/views/paket/ListPaket.vue"),
      meta: {
        layout: "full",
      },
    },
    
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/register",
      name: "register",
      component: () => import("@/views/Register.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },

    //-------------------------------------------ADMIN -------------------------------------
    {
      path: "/admin/dashboard",
      name: "dashboard_admin",
      component: () => import("@/views/admin/Dashboard.vue"),
      meta: {
        pageTitle: "Dashboard",
        breadcrumb: [
          {
            text: "Informasi",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/approval",
      name: "list_approval_akun",
      component: () => import("@/views/admin/approval/ListApproval.vue"),
      meta: {
        pageTitle: "Approval Akun",
        breadcrumb: [
          {
            text: "Daftar Aktivasi Akun",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/edit_approval_akun/:id",
      name: "edit_approval_akun",
      component: () => import("@/views/admin/approval/EditApproval.vue"),
      meta: {
        pageTitle: "Approval Akun",
        breadcrumb: [
          {
            text: "Detail Aktivasi Akun",
            active: true,
          },
        ],
      },
    },

    {
      path: "/admin/category",
      name: "list_category",
      component: () => import("@/views/admin/category/ListCategory.vue"),
      meta: {
        pageTitle: "Kriteria Tryout",
        breadcrumb: [
          {
            text: "Daftar Kriteria Tryout",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/add_category",
      name: "add_category",
      component: () => import("@/views/admin/category/AddCategory.vue"),
      meta: {
        pageTitle: "Kriteria Tryout",
        breadcrumb: [
          {
            text: "Tambah Kriteria Tryout",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/edit_category/:id",
      name: "edit_category",
      component: () => import("@/views/admin/category/EditCategory.vue"),
      meta: {
        pageTitle: "Kriteria Tryout",
        breadcrumb: [
          {
            text: "Edit Kriteria Tryout",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/student",
      name: "list_student",
      component: () => import("@/views/admin/student/ListStudent.vue"),
      meta: {
        pageTitle: "Peserta",
        breadcrumb: [
          {
            text: "Daftar Peserta",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/edit_student/:id",
      name: "edit_student",
      component: () => import("@/views/admin/student/EditStudent.vue"),
      meta: {
        pageTitle: "Peserta",
        breadcrumb: [
          {
            text: "Edit Peserta",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/teacher",
      name: "list_teacher",
      component: () => import("@/views/admin/teacher/ListTeacher.vue"),
      meta: {
        pageTitle: "Dosen",
        breadcrumb: [
          {
            text: "Daftar Dosen",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/edit_teacher/:id",
      name: "edit_teacher",
      component: () => import("@/views/admin/teacher/EditTeacher.vue"),
      meta: {
        pageTitle: "Dosen",
        breadcrumb: [
          {
            text: "Edit Dosen",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/add_teacher",
      name: "add_teacher",
      component: () => import("@/views/admin/teacher/AddTeacher.vue"),
      meta: {
        pageTitle: "Dosen",
        breadcrumb: [
          {
            text: "Add Dosen",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/study",
      name: "list_study",
      component: () => import("@/views/admin/study/ListStudy.vue"),
      meta: {
        pageTitle: "Pelajaran",
        breadcrumb: [
          {
            text: "Daftar Pelajaran",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/add_study",
      name: "add_study",
      component: () => import("@/views/admin/study/AddStudy.vue"),
      meta: {
        pageTitle: "Pelajaran",
        breadcrumb: [
          {
            text: "Tambah Pelajaran",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/edit_study/:id",
      name: "edit_study",
      component: () => import("@/views/admin/study/EditStudy.vue"),
      meta: {
        pageTitle: "Pelajaran",
        breadcrumb: [
          {
            text: "Edit Pelajaran",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/tinjauan_satu",
      name: "list_tinjauan_satu",
      component: () => import("@/views/admin/tinjauan_satu/ListTinjauanSatu.vue"),
      meta: {
        pageTitle: "Tinjauan",
        breadcrumb: [
          {
            text: "Daftar Tinjauan 1",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/add_tinjauan_satu",
      name: "add_tinjauan_satu",
      component: () => import("@/views/admin/tinjauan_satu/AddTinjauanSatu.vue"),
      meta: {
        pageTitle: "Tinjauan",
        breadcrumb: [
          {
            text: "Tambah Tinjauan 1",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/edit_tinjauan_satu/:id",
      name: "edit_tinjauan_satu",
      component: () => import("@/views/admin/tinjauan_satu/EditTinjauanSatu.vue"),
      meta: {
        pageTitle: "Tinjauan",
        breadcrumb: [
          {
            text: "Edit Tinjauan 1",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/tinjauan_dua",
      name: "list_tinjauan_dua",
      component: () => import("@/views/admin/tinjauan_dua/ListTinjauanDua.vue"),
      meta: {
        pageTitle: "Tinjauan",
        breadcrumb: [
          {
            text: "Daftar Tinjauan 2",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/add_tinjauan_dua",
      name: "add_tinjauan_dua",
      component: () => import("@/views/admin/tinjauan_dua/AddTinjauanDua.vue"),
      meta: {
        pageTitle: "Tinjauan",
        breadcrumb: [
          {
            text: "Tambah Tinjauan 2",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/edit_tinjauan_dua/:id",
      name: "edit_tinjauan_dua",
      component: () => import("@/views/admin/tinjauan_dua/EditTinjauanDua.vue"),
      meta: {
        pageTitle: "Tinjauan",
        breadcrumb: [
          {
            text: "Edit Tinjauan 2",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/tinjauan_tiga",
      name: "list_tinjauan_tiga",
      component: () => import("@/views/admin/tinjauan_tiga/ListTinjauanTiga.vue"),
      meta: {
        pageTitle: "Tinjauan",
        breadcrumb: [
          {
            text: "Daftar Tinjauan 3",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/add_tinjauan_tiga",
      name: "add_tinjauan_tiga",
      component: () => import("@/views/admin/tinjauan_tiga/AddTinjauanTiga.vue"),
      meta: {
        pageTitle: "Tinjauan",
        breadcrumb: [
          {
            text: "Tambah Tinjauan 3",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/edit_tinjauan_tiga/:id",
      name: "edit_tinjauan_tiga",
      component: () => import("@/views/admin/tinjauan_tiga/EditTinjauanTiga.vue"),
      meta: {
        pageTitle: "Tinjauan",
        breadcrumb: [
          {
            text: "Edit Tinjauan 3",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/tinjauan_empat",
      name: "list_tinjauan_empat",
      component: () => import("@/views/admin/tinjauan_empat/ListTinjauanEmpat.vue"),
      meta: {
        pageTitle: "Tinjauan",
        breadcrumb: [
          {
            text: "Daftar Tinjauan 4",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/add_tinjauan_empat",
      name: "add_tinjauan_empat",
      component: () => import("@/views/admin/tinjauan_empat/AddTinjauanEmpat.vue"),
      meta: {
        pageTitle: "Tinjauan",
        breadcrumb: [
          {
            text: "Tambah Tinjauan 4",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/edit_tinjauan_empat/:id",
      name: "edit_tinjauan_empat",
      component: () => import("@/views/admin/tinjauan_empat/EditTinjauanEmpat.vue"),
      meta: {
        pageTitle: "Tinjauan",
        breadcrumb: [
          {
            text: "Edit Tinjauan 4",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/tinjauan_lima",
      name: "list_tinjauan_lima",
      component: () => import("@/views/admin/tinjauan_lima/ListTinjauanLima.vue"),
      meta: {
        pageTitle: "Tinjauan",
        breadcrumb: [
          {
            text: "Daftar Tinjauan 5",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/add_tinjauan_lima",
      name: "add_tinjauan_lima",
      component: () => import("@/views/admin/tinjauan_lima/AddTinjauanLima.vue"),
      meta: {
        pageTitle: "Tinjauan",
        breadcrumb: [
          {
            text: "Tambah Tinjauan 5",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/edit_tinjauan_lima/:id",
      name: "edit_tinjauan_lima",
      component: () => import("@/views/admin/tinjauan_lima/EditTinjauanLima.vue"),
      meta: {
        pageTitle: "Tinjauan",
        breadcrumb: [
          {
            text: "Edit Tinjauan 5",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/tinjauan_enam",
      name: "list_tinjauan_enam",
      component: () => import("@/views/admin/tinjauan_enam/ListTinjauanEnam.vue"),
      meta: {
        pageTitle: "Tinjauan",
        breadcrumb: [
          {
            text: "Daftar Tinjauan 6",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/add_tinjauan_enam",
      name: "add_tinjauan_enam",
      component: () => import("@/views/admin/tinjauan_enam/AddTinjauanEnam.vue"),
      meta: {
        pageTitle: "Tinjauan",
        breadcrumb: [
          {
            text: "Tambah Tinjauan 6",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/edit_tinjauan_enam/:id",
      name: "edit_tinjauan_enam",
      component: () => import("@/views/admin/tinjauan_enam/EditTinjauanEnam.vue"),
      meta: {
        pageTitle: "Tinjauan",
        breadcrumb: [
          {
            text: "Edit Tinjauan 6",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/tinjauan_tujuh",
      name: "list_tinjauan_tujuh",
      component: () => import("@/views/admin/tinjauan_tujuh/ListTinjauanTujuh.vue"),
      meta: {
        pageTitle: "Tinjauan",
        breadcrumb: [
          {
            text: "Daftar Tinjauan 7",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/add_tinjauan_tujuh",
      name: "add_tinjauan_tujuh",
      component: () => import("@/views/admin/tinjauan_tujuh/AddTinjauanTujuh.vue"),
      meta: {
        pageTitle: "Tinjauan",
        breadcrumb: [
          {
            text: "Tambah Tinjauan 7",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/edit_tinjauan_tujuh/:id",
      name: "edit_tinjauan_tujuh",
      component: () => import("@/views/admin/tinjauan_tujuh/EditTinjauanTujuh.vue"),
      meta: {
        pageTitle: "Tinjauan",
        breadcrumb: [
          {
            text: "Edit Tinjauan 7",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/tinjauan_delapan",
      name: "list_tinjauan_delapan",
      component: () => import("@/views/admin/tinjauan_delapan/ListTinjauanDelapan.vue"),
      meta: {
        pageTitle: "Tinjauan",
        breadcrumb: [
          {
            text: "Daftar Tinjauan 8",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/add_tinjauan_delapan",
      name: "add_tinjauan_delapan",
      component: () => import("@/views/admin/tinjauan_delapan/AddTinjauanDelapan.vue"),
      meta: {
        pageTitle: "Tinjauan",
        breadcrumb: [
          {
            text: "Tambah Tinjauan 8",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/edit_tinjauan_delapan/:id",
      name: "edit_tinjauan_delapan",
      component: () => import("@/views/admin/tinjauan_delapan/EditTinjauanDelapan.vue"),
      meta: {
        pageTitle: "Tinjauan",
        breadcrumb: [
          {
            text: "Edit Tinjauan 8",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/tinjauan_sembilan",
      name: "list_tinjauan_sembilan",
      component: () => import("@/views/admin/tinjauan_sembilan/ListTinjauanSembilan.vue"),
      meta: {
        pageTitle: "Tinjauan",
        breadcrumb: [
          {
            text: "Daftar Tinjauan 9",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/add_tinjauan_sembilan",
      name: "add_tinjauan_sembilan",
      component: () => import("@/views/admin/tinjauan_sembilan/AddTinjauanSembilan.vue"),
      meta: {
        pageTitle: "Tinjauan",
        breadcrumb: [
          {
            text: "Tambah Tinjauan 9",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/edit_tinjauan_sembilan/:id",
      name: "edit_tinjauan_sembilan",
      component: () => import("@/views/admin/tinjauan_sembilan/EditTinjauanSembilan.vue"),
      meta: {
        pageTitle: "Tinjauan",
        breadcrumb: [
          {
            text: "Edit Tinjauan 9",
            active: true,
          },
        ],
      },
    },

    {
      path: "/admin/discount",
      name: "list_discount",
      component: () => import("@/views/admin/discount/ListDiscount.vue"),
      meta: {
        pageTitle: "Diskon",
        breadcrumb: [
          {
            text: "Daftar Diskon",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/add_discount",
      name: "add_discount",
      component: () => import("@/views/admin/discount/AddDiscount.vue"),
      meta: {
        pageTitle: "Diskon",
        breadcrumb: [
          {
            text: "Daftar Diskon",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/edit_discount/:id",
      name: "edit_discount",
      component: () => import("@/views/admin/discount/EditDiscount.vue"),
      meta: {
        pageTitle: "Diskon",
        breadcrumb: [
          {
            text: "Daftar Diskon",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/event",
      name: "list_event",
      component: () => import("@/views/admin/event/ListEvent.vue"),
      meta: {
        pageTitle: "Event Tryout",
        breadcrumb: [
          {
            text: "Daftar Event",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/edit_event/:id",
      name: "edit_event",
      component: () => import("@/views/admin/event/EditEvent.vue"),
      meta: {
        pageTitle: "Event Tryout",
        breadcrumb: [
          {
            text: "Edit Event",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/add_event",
      name: "add_event",
      component: () => import("@/views/admin/event/AddEvent.vue"),
      meta: {
        pageTitle: "Event Tryout",
        breadcrumb: [
          {
            text: "Add Event",
            active: true,
          },
        ],
      },
    },

    {
      path: "/admin/plan",
      name: "list_plan",
      component: () => import("@/views/admin/plan/ListPlan.vue"),
      meta: {
        pageTitle: "Jadwal Tryout",
        breadcrumb: [
          {
            text: "Daftar Jadwal Tryout",
            active: true,
          },
        ],
      },
    },

    {
      path: "/admin/edit_plan/:id",
      name: "edit_plan",
      component: () => import("@/views/admin/plan/EditPlan.vue"),
      meta: {
        pageTitle: "Jadwal Tryout",
        breadcrumb: [
          {
            text: "Edit Jadwal Tryout",
            active: true,
          },
        ],
      },
    },

    {
      path: "/admin/register_plan/:id",
      name: "register_plan",
      component: () => import("@/views/admin/plan/RegisterPlan.vue"),
      meta: {
        pageTitle: "Register",
        breadcrumb: [
          {
            text: "Peserta",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/register_question/:id",
      name: "register_question",
      component: () => import("@/views/admin/plan/RegisterQuestion.vue"),
      meta: {
        pageTitle: "Register",
        breadcrumb: [
          {
            text: "Pertanyaan",
            active: true,
          },
        ],
      },
    },

    {
      path: "/admin/add_plan",
      name: "add_plan",
      component: () => import("@/views/admin/plan/AddPlan.vue"),
      meta: {
        pageTitle: "Jadwal Tryout",
        breadcrumb: [
          {
            text: "Tambah Jadwal Tryout",
            active: true,
          },
        ],
      },
    },

    {
      path: "/admin/question",
      name: "list_question",
      component: () => import("@/views/admin/question/ListQuestion.vue"),
      meta: {
        pageTitle: "Bank Soal",
        breadcrumb: [
          {
            text: "Daftar Bank Soal",
            active: true,
          },
        ],
      },
    },

    {
      path: "/admin/edit_question/:id",
      name: "edit_question",
      component: () => import("@/views/admin/question/EditQuestion.vue"),
      meta: {
        pageTitle: "Bank Soal",
        breadcrumb: [
          {
            text: "Edit Bank Soal",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/add_question",
      name: "add_question",
      component: () => import("@/views/admin/question/AddQuestion.vue"),
      meta: {
        pageTitle: "Bank Soal",
        breadcrumb: [
          {
            text: "Tambah Bank Soal",
            active: true,
          },
        ],
      },
    },

    {
      path: "/admin/list_status_exam",
      name: "list_status_exam_admin",
      component: () => import("@/views/admin/status/ListStatusExam.vue"),
      meta: {
        pageTitle: "Status Ujian",
        breadcrumb: [
          {
            text: "Daftar Status Ujian",
            active: true,
          },
        ],
      },
    },

    {
      path: "/admin/list_status_student",
      name: "list_status_student_admin",
      component: () => import("@/views/admin/status/ListStatusStudent.vue"),
      meta: {
        pageTitle: "Status Peserta",
        breadcrumb: [
          {
            text: "Daftar Status Peserta",
            active: true,
          },
        ],
      },
    },

    {
      path: "/admin/list_result",
      name: "list_result_admin",
      component: () => import("@/views/admin/result/ListResult.vue"),
      meta: {
        pageTitle: "Hasil Tryout",
        breadcrumb: [
          {
            text: "Daftar Hasil Tryout",
            active: true,
          },
        ],
      },
    },

    {
      path: "/admin/list_analysis",
      name: "list_analysis_admin",
      component: () => import("@/views/admin/analysis/ListAnalysis.vue"),
      meta: {
        pageTitle: "Analisa Tryout",
        breadcrumb: [
          {
            text: "Daftar Analisa Tryout",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/blog",
      name: "list_blog",
      component: () => import("@/views/admin/artikel/ListArtikel.vue"),
      meta: {
        pageTitle: "Artikel",
        breadcrumb: [
          {
            text: "Daftar Artikel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/add_blog",
      name: "add_blog",
      component: () => import("@/views/admin/artikel/AddArtikel.vue"),
      meta: {
        pageTitle: "Artikel",
        breadcrumb: [
          {
            text: "Tambah Artikel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/edit_blog/:id",
      name: "edit_blog",
      component: () => import("@/views/admin/artikel/EditArtikel.vue"),
      meta: {
        pageTitle: "Artikel",
        breadcrumb: [
          {
            text: "Edit Artikel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/fitur_activity",
      name: "fitur_activity",
      component: () => import("@/views/admin/fituractivity/ListFiturActivity.vue"),
      meta: {
        pageTitle: "Fitur & Aktivitas",
        breadcrumb: [
          {
            text: "Daftar Fitur & Aktivitas",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/add_fitur_activity",
      name: "add_fitur_activity",
      component: () => import("@/views/admin/fituractivity/AddFiturActivity.vue"),
      meta: {
        pageTitle: "Fitur & Aktivitas",
        breadcrumb: [
          {
            text: "Add Fitur & Aktivitas",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/edit_fitur_activity/:id",
      name: "edit_fitur_activity",
      component: () => import("@/views/admin/fituractivity/EditFiturActivity.vue"),
      meta: {
        pageTitle: "Fitur & Aktivitas",
        breadcrumb: [
          {
            text: "Edit Fitur & Aktivitas",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/product",
      name: "list_product",
      component: () => import("@/views/admin/product/ListProduct.vue"),
      meta: {
        pageTitle: "Produk",
        breadcrumb: [
          {
            text: "Daftar Produk",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/edit_product/:id",
      name: "edit_product",
      component: () => import("@/views/admin/product/EditProduct.vue"),
      meta: {
        pageTitle: "Produk",
        breadcrumb: [
          {
            text: "Edit Produk",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/add_product",
      name: "add_product",
      component: () => import("@/views/admin/product/AddProduct.vue"),
      meta: {
        pageTitle: "Produk",
        breadcrumb: [
          {
            text: "Tambah Produk",
            active: true,
          },
        ],
      },
    },

    {
      path: "/admin/banner",
      name: "list_banner",
      component: () => import("@/views/admin/banner/ListBanner.vue"),
      meta: {
        pageTitle: "Banner",
        breadcrumb: [
          {
            text: "Daftar Banner",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/edit_banner/:id",
      name: "edit_banner",
      component: () => import("@/views/admin/banner/EditBanner.vue"),
      meta: {
        pageTitle: "Banner",
        breadcrumb: [
          {
            text: "Edit Banner",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/add_banner",
      name: "add_banner",
      component: () => import("@/views/admin/banner/AddBanner.vue"),
      meta: {
        pageTitle: "Banner",
        breadcrumb: [
          {
            text: "Tambah Banner",
            active: true,
          },
        ],
      },
    },

    {
      path: "/admin/contact",
      name: "list_contact",
      component: () => import("@/views/admin/contact/ListContact.vue"),
      meta: {
        pageTitle: "Kontak",
        breadcrumb: [
          {
            text: "Daftar Kontak",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/edit_contact/:id",
      name: "edit_contact",
      component: () => import("@/views/admin/contact/EditContact.vue"),
      meta: {
        pageTitle: "Kontak",
        breadcrumb: [
          {
            text: "Edit Kontak",
            active: true,
          },
        ],
      },
    },

    {
      path: "/admin/paket",
      name: "list_paket",
      component: () => import("@/views/admin/paket/ListPaket.vue"),
      meta: {
        pageTitle: "Paket",
        breadcrumb: [
          {
            text: "Daftar Paket",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/edit_paket/:id",
      name: "edit_paket",
      component: () => import("@/views/admin/paket/EditPaket.vue"),
      meta: {
        pageTitle: "Paket",
        breadcrumb: [
          {
            text: "Edit Paket",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/add_paket",
      name: "add_paket",
      component: () => import("@/views/admin/paket/AddPaket.vue"),
      meta: {
        pageTitle: "Paket",
        breadcrumb: [
          {
            text: "Tambah Paket",
            active: true,
          },
        ],
      },
    },

    {
      path: "/admin/collage",
      name: "list_collage",
      component: () => import("@/views/admin/collage/ListCollage.vue"),
      meta: {
        pageTitle: "Kampus",
        breadcrumb: [
          {
            text: "Daftar Kampus",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/edit_collage/:id",
      name: "edit_collage",
      component: () => import("@/views/admin/collage/EditCollage.vue"),
      meta: {
        pageTitle: "Kampus",
        breadcrumb: [
          {
            text: "Edit Kampus",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/add_collage",
      name: "add_collage",
      component: () => import("@/views/admin/collage/AddCollage.vue"),
      meta: {
        pageTitle: "Kampus",
        breadcrumb: [
          {
            text: "Tambah Kampus",
            active: true,
          },
        ],
      },
    },

    {
      path: "/admin/bimbel",
      name: "list_bimbel",
      component: () => import("@/views/admin/bimbel/ListBimbel.vue"),
      meta: {
        pageTitle: "Bimbel",
        breadcrumb: [
          {
            text: "Daftar Bimbel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/edit_bimbel/:id",
      name: "edit_bimbel",
      component: () => import("@/views/admin/bimbel/EditBimbel.vue"),
      meta: {
        pageTitle: "Bimbel",
        breadcrumb: [
          {
            text: "Edit Bimbel",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/add_bimbel",
      name: "add_bimbel",
      component: () => import("@/views/admin/bimbel/AddBimbel.vue"),
      meta: {
        pageTitle: "Bimbel",
        breadcrumb: [
          {
            text: "Tambah Bimbel",
            active: true,
          },
        ],
      },
    },

    {
      path: "/admin/osce",
      name: "list_osce",
      component: () => import("@/views/admin/osce/ListOsce.vue"),
      meta: {
        pageTitle: "OSCE",
        breadcrumb: [
          {
            text: "Daftar OSCE",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/edit_osce/:id",
      name: "edit_osce",
      component: () => import("@/views/admin/osce/EditOsce.vue"),
      meta: {
        pageTitle: "OSCE",
        breadcrumb: [
          {
            text: "Edit OSCE",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/add_osce",
      name: "add_osce",
      component: () => import("@/views/admin/osce/AddOsce.vue"),
      meta: {
        pageTitle: "OSCE",
        breadcrumb: [
          {
            text: "Tambah OSCE",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/list_pembayaran",
      name: "list_pembayaran_admin",
      component: () => import("@/views/admin/report/ListPembayaran.vue"),
      meta: {
        pageTitle: "Laporan",
        breadcrumb: [
          {
            text: "Daftar Pembayaran",
            active: true,
          },
        ],
      },
    },
    {
      path: "/admin/detail_pembayaran/:id",
      name: "detail_pembayaran_admin",
      component: () => import("@/views/admin/report/DetailPembayaran.vue"),
      meta: {
        pageTitle: "Laporan",
        breadcrumb: [
          {
            text: "Daftar Pembayaran",
            active: true,
          },
        ],
      },
    },


    //------------------------------------------- USER -------------------------------------
    {
      path: "/user/dashboard",
      name: "dashboard_user",
      component: () => import("@/views/user/Dashboard.vue"),
      meta: {
        pageTitle: "Dashboard",
        breadcrumb: [
          {
            text: "Dashboard",
            active: true,
          },
        ],
      },
    },
    {
      path: "/user/bimbel",
      name: "bimbel_user",
      component: () => import("@/views/user/bimbel/ListBimbel.vue"),
      meta: {
        pageTitle: "Bimbingan Belajar",
        breadcrumb: [
          {
            text: "Video",
            active: true,
          },
        ],
      },
    },
    {
      path: "/user/bimbel/detail/:id",
      name: "detail_bimbel",
      component: () => import("@/views/user/bimbel/DetailBimbel.vue"),
      meta: {
        pageTitle: "Bimbingan Belajar",
        breadcrumb: [
          {
            text: "Video",
            active: true,
          },
        ],
      },
    },
    {
      path: "/user/tryout",
      name: "list_tryout",
      component: () => import("@/views/user/tryout/ListTryout.vue"),
      meta: {
        pageTitle: "Daftar",
        breadcrumb: [
          {
            text: "Jadwal TryOut",
            active: true,
          },
        ],
      },
    },
    {
      path: "/user/prepare_tryout/:id/:durasi",
      name: "prepare_tryout",
      component: () => import("@/views/user/tryout/PrepareQuestion.vue"),
      meta: {
        pageTitle: "Persiapan",
        breadcrumb: [
          {
            text: "Ujian",
            active: true,
          },
        ],
      },
    },
    {
      path: "/user/question_tryout/:id",
      name: "question_tryout",
      component: () => import("@/views/user/tryout/Question.vue"),
      meta: {
      //   pageTitle: "Ujian",
      //   breadcrumb: [
      //     {
      //       text: "Pertanyaan",
      //       active: true,
      //     },
      //   ],
        layout: "full",
      },
    },
    {
      path: "/user/finish_tryout/:id",
      name: "finish_tryout",
      component: () => import("@/views/user/tryout/FinishTryout.vue"),
      meta: {
        pageTitle: "Ujian",
        breadcrumb: [
          {
            text: "Selesai",
            active: true,
          },
        ],
      },
    },
    {
      path: "/user/osce",
      name: "osce_user",
      component: () => import("@/views/user/osce/ListOsce.vue"),
      meta: {
        pageTitle: "Video Praktikum",
        breadcrumb: [
          {
            text: "OSCE",
            active: true,
          },
        ],
      },
    },
    {
      path: "/user/osce/detail/:id",
      name: "detail_osce",
      component: () => import("@/views/user/osce/DetailOsce.vue"),
      meta: {
        pageTitle: "OSCE",
        breadcrumb: [
          {
            text: "Video",
            active: true,
          },
        ],
      },
    },
    {
      path: "/user/profile",
      name: "profile",
      component: () => import("@/views/profile/AccountSetting.vue"),
      meta: {
        pageTitle: "Profil",
        breadcrumb: [
          {
            text: "Informasi Pribadi",
            active: true,
          },
        ],
      },
    },

    {
      path: "/user/upgrade_akun",
      name: "upgrade_akun",
      component: () => import("@/views/user/upgrade/UpgradeAkun.vue"),
      meta: {
        pageTitle: "Upgrade",
        breadcrumb: [
          {
            text: "Daftar Harga dan Paket",
            active: true,
          },
        ],
      },
    },
    {
      path: "/user/add_upgrade_akun/:id",
      name: "add_upgrade_akun",
      component: () => import("@/views/user/upgrade/AddUpgradeAkun.vue"),
      meta: {
        pageTitle: "Upgrade",
        breadcrumb: [
          {
            text: "Transaksi",
            active: true,
          },
        ],
      },
    },
    {
      path: "/user/list_pembayaran",
      name: "list_pembayaran_user",
      component: () => import("@/views/user/report/ListPembayaran.vue"),
      meta: {
        pageTitle: "Laporan",
        breadcrumb: [
          {
            text: "Daftar Pembayaran",
            active: true,
          },
        ],
      },
    },
    {
      path: "/user/detail_pembayaran/:id",
      name: "detail_pembayaran_user",
      component: () => import("@/views/user/report/DetailPembayaran.vue"),
      meta: {
        pageTitle: "Laporan",
        breadcrumb: [
          {
            text: "Daftar Pembayaran",
            active: true,
          },
        ],
      },
    },
    {
      path: "/user/list_hasil_tryout",
      name: "list_hasil_tryout_user",
      component: () => import("@/views/user/report/ListHasilTryout.vue"),
      meta: {
        pageTitle: "Laporan",
        breadcrumb: [
          {
            text: "Daftar Hasil Tryout",
            active: true,
          },
        ],
      },
    },
    {
      path: "/user/list_pembahasan_hasil_tryout/:id",
      name: "list_pembahasan_hasil_tryout_user",
      component: () => import("@/views/user/report/ListPembahasanTryout.vue"),
      meta: {
        pageTitle: "Laporan",
        // breadcrumb: [
        //   {
        //     text: "Daftar Pembahasan Tryout",
        //     active: true,
        //   },
        // ],
        layout: "full"
      },
    },
    {
      path: "/user/list_analisa_hasil_tryout/:id",
      name: "list_analisa_hasil_tryout_user",
      component: () => import("@/views/user/report/ListAnalisaTryout.vue"),
      meta: {
        pageTitle: "Laporan",
        // breadcrumb: [
        //   {
        //     text: "Daftar Analisa Tryout",
        //     active: true,
        //   },
        // ],
        layout: "full"
      },
    },
    {
      path: "/user/detail_hasil_tryout/:id",
      name: "detail_hasil_tryout_user",
      component: () => import("@/views/user/report/DetailHasilTryout.vue"),
      meta: {
        pageTitle: "Laporan",
        breadcrumb: [
          {
            text: "Detail Pembahasan Tryout ",
            active: true,
          },
        ],
      },
    },

  ],
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
